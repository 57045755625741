.App {
  text-align: center;
  width:100%;
  overflow: hidden;
  background-color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.flexRowCentred {
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.flexRowSpaceBetween {
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.flexColumnCentred {
  flex-direction: column;
  align-items: center;
  vertical-align: middle;
  align-content: center;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

.logo {
  width:200px;
  height:auto;
  margin-right: 30px;
  margin-left: 10px;
}

.cardImgBG {
  height:100%;
  margin-left: 0px;
  top:0;
  left:0;
}

.imageBackground {
  background-color: #162236;
  color: white;
}

.imageWrapper {
  height:100%;
  overflow: hidden;
  position: relative;
}

.imageWrapper img {
  object-fit: cover;
  object-position: 100% 10%; /* positioned top left of the content box */
  height:320px;
}

img {
  width:100%;
}

.imageOverlay {
  background-color: rgba(22,34,54,0.85);
  margin-right: 20px;
  position: absolute;
  bottom: 35px;
  left: 35px;
  font-size: 30px;
  font-weight: 700;
  text-align: left;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  line-height: 40px;
}

.mainImage {
  overflow: hidden
}

.paddingStandard {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 20px;
  padding-bottom: 0px;
}

.paddingCards {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 20px;
  padding-bottom: 0px;
}

/*HOME SCREEN*/

.servicesCard {
  color: white;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 0px;
  position: relative;
}

.cardBackground {
  height:100%;
  overflow: hidden;
  position: relative;
  width:100%;
  background-color: white;
}

.cardBackground img {
  object-fit: cover;
  height:200px;
  width:100%;
}

.cardWidth {
  position: absolute;
  top: 0px;
  left: 10px;
  padding-left: 20px;
  padding-right: 30px;
  padding-top: 5px;
}

.servicesCard:hover{
  opacity: 0.9;
}

.servicesCard a {
  font-size: 14px;
  font-weight: bold;
  margin-top: 0px;
}

.servicesCard h4 {
  font-weight: 700;
  min-height: 60px;
  margin-top: 10px;
  margin-bottom: 0px;
}

.sectionBreak {
  width:100%;
  height:20px;
}

.sectionBreakSmall {
  width:100%;
  height:5px;
}

.sectionBreakNav {
}

.backgroundGrey {
  background-color: rgba(22,34,54,0.025);
}

.backgroundWhite {
  background-color: red;
}

.color1{
  color: white;
}

.color2{
  color: white;
}

.color3{
  color: white;
}

.colorFooter {
  color: white;
  background-color: #162236;
}

.textAlignLeft {
  text-align: left;
}

.paddingTextBody p {
  margin-left: 10px;
  margin-right: 10px;
}

.footerMain {
  padding: 20px;
}

.footerText {
  font-size: 12px;
}

.footerWrapper {
  padding-left:30px;
}

.footerPhone {
  flex-direction: row;
  align-items: flex-start;
  vertical-align: middle;
  justify-content: flex-start;
}

.phoneTitle {
  font-size: 14px;
  font-weight: 700;
  opacity: 0.5;
}

.phoneNumber {
  font-size: 24px;
  font-weight: 700;
  margin-top: -10px;
}

.adjustedMarginTop {
  margin-top: -20px;
}

.mainTextWrapper {
  margin-bottom: 30px;
}

.mainTextWrapper h1 {
  font-weight: 700;
  font-size: 36px;
  color: #79288C;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(22,34,54,0.05);
}

.mainTextWrapper h2 {
  font-weight: 700;
  font-size: 24px;
}

.mainTextWrapper h3 {
  font-weight: 700;
  font-size: 18px;
  color: #79288C;
  margin-bottom: 20px;
}

.headerWrapper {
  background-color: rgba(22,34,54,1);
  text-align: left;
}

.headerWrapper h4 {
  color: white;
  padding-left:20px;
}

.nameLink {
  color:black;
}

.alignLeft {
  text-align: left;
}

.adjustedCardMargin {

}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
